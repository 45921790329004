import {
  BookedSlot,
  Type,
  GroupedResources,
} from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormNestedSlot } from '../../types/formSelectedSlots';

export const mapFormNestedSlotToServerSlot = (
  slot?: FormNestedSlot,
  v2Availability?: boolean,
): BookedSlot | undefined => {
  if (!slot) {
    return undefined;
  }

  const resource = slot.resource;
  let flowSelectedResources: GroupedResources[] = [];

  if (v2Availability && !resource) {
    flowSelectedResources = [{ type: Type.DO_NOT_CARE }];
  }

  return {
    startDate: slot.startDate === null ? undefined : slot.startDate,
    endDate: slot.endDate === null ? undefined : slot.endDate,
    timezone: slot.timezone === null ? undefined : slot.timezone,
    sessionId: slot.sessionId === null ? undefined : slot.sessionId,
    location: slot.location,
    ...(flowSelectedResources.length
      ? { flowSelectedResources }
      : { resource }),
    scheduleId: slot.scheduleId === null ? undefined : slot.scheduleId,
    serviceId: slot.serviceId === null ? undefined : slot.serviceId,
  };
};

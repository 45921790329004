import {
  Booking,
  ContactDetails,
  CustomFormField,
  BulkCreateBookingRequest,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { bulkCreateBooking } from '@wix/ambassador-bookings-v2-booking/http';
import { createBookingEntity } from './utils';
import { SlotService } from '../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../utils';

export const sendCreateBookingsRequest = async (
  httpClient: IHttpClient,
  slotServices: SlotService[],
  contactDetails: ContactDetails,
  additionalFields: CustomFormField[],
  numberOfParticipants: number | undefined,
  sendSmsReminder: boolean | undefined,
  v2Availability: boolean | undefined,
) => {
  const singleRequestsRequests = slotServices.map(
    (slotService: SlotService) => {
      const booking: Booking = createBookingEntity({
        slotService,
        numberOfParticipants,
        additionalFields,
        contactDetails,
        v2Availability,
      });
      return {
        booking,
        sendSmsReminder,
        participantNotification: {
          notifyParticipants: true,
        },
      };
    },
  );

  const bulkCreateBookingRequest: BulkCreateBookingRequest = {
    createBookingsInfo: singleRequestsRequests,
  };
  const requestRes = await httpClient.request(
    bulkCreateBooking(bulkCreateBookingRequest),
  );

  if (requestRes?.data?.bulkActionMetadata?.totalFailures) {
    throw new Error('At least one of bookings in Bulk Request are failed');
  }

  const result: { [key: string]: string } = {};
  if (requestRes.data.results?.length) {
    slotServices.forEach((slotService: SlotService, index: number) => {
      result[getServiceSlotIdentifier(slotService.nestedSlot)] =
        requestRes.data.results![index]?.itemMetadata?.id!;
    });
  }

  return result;
};
